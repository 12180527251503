import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import React, { Suspense } from "react";
import SideNavigation from "../../components/sideNavigation";
import Header from "../../components/header";
import { useSelector } from "react-redux";
import { ReducersModel } from "../../model";
import Utils from "../../Utils";
const styles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      paddingTop: "69px",
      // minHeight: "100%",
      // height: "100%",
      width: "100%",
      boxSizing: "border-box",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0 !important",
        marginTop:'50px'
      },
      [theme.breakpoints.between(0, 600)]: {
        paddingLeft: "0 !important",
        paddingTop: 0,
      },
      [theme.breakpoints.between(0, 1025)]: {
        paddingTop: 0,
      },
      // justifyContent: "space-between",
    },
    headerAndBodyContainer: {
      width: "100%",
      height: "100%",
      backgroundColor: "#F9FBFB",
      transition: "width 0.5s ease-in-out",
     
    },
    toggleOn: {
      transition: "width 0.5s ease-in-out",
      width: "100%",
    },
    bodyContainer: {
      minHeight: "calc(95vh - 71px)",
      width: "100%",
      height: "100%",
      [theme.breakpoints.down(600)]: {
        height: "auto",
          paddingLeft:"0 !important",
          paddingBottom: "35px", 
      },
      [theme.breakpoints.down(800)]: {
        height: "auto",
        paddingBottom: "15px",
      },
    },
  })
);
interface Props {
  children?: any;
}
function MainContainer({ children }: Props) {
  const classes = styles();
  const path_name = location.pathname.split("/").slice(1)[0];
  const window_width = window.innerWidth;
  const { toggle } = useSelector(
    (state: ReducersModel) => state.toggleSideNavReducer
  );
  return (
    <div
      className={classes.mainContainer}
      style={
        toggle
          ? { paddingLeft: "0", transition: "padding 0.5s ease-in-out" }
          : { paddingLeft: "248px", transition: "padding 0.5s ease-in-out" }
      }
    >
      {path_name == "video-editor" && window_width < 1080 ? (
        ""
      ) : (
        <SideNavigation />
      )}

      <div
        className={
          toggle
            ? `${classes.headerAndBodyContainer} ${classes.toggleOn}`
            : classes.headerAndBodyContainer
        }
      >
        {path_name == "video-editor" && window_width < 1080 ? "" : <Header />}

        <Suspense fallback={""}>
          <div
            className={classes.bodyContainer}
            style={
              toggle
                ? { paddingLeft: "60px", transition: "padding 0.5s ease-in-out" }
                : { paddingLeft: "0", transition: "padding 0.5s ease-in-out" }
            }
          >
            {children}
          </div>
        </Suspense>
      </div>
    </div>
  );
}

export default MainContainer;
