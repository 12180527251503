import React from "react";
import { Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Turn as Hamburger } from "hamburger-react";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModel } from "../../model";
import Utils from "../../Utils";
import LocalImages from "../../Utils/images";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import templateList, {
  getRecentSearchResults,
  checkSubscription,
} from "./action";
import Dropdown from "./dropDownMenu";
import { useNavigate } from "react-router-dom";
import DropdownContent from "./headerDropdownContent";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
      zIndex: 999,
      backgroundColor: "#F9FBFB",
      position: "fixed",
      left: "0px",
      top: "0px",
      marginLeft: "229.5px",
      width: "calc(100% - 249px)",
      padding: "0 0 0 10px",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #EAEAEA",
      [theme.breakpoints.down(600)]: {
        width: "100% !important",
        marginLeft: "0 !important",
      },
    },
    toggleSideNav: {
      marginTop: "10px",
      marginBottom: "10px",
      width: "30%",
      [theme.breakpoints.between(300, 600)]: {
        width: "15%",
      },
      [theme.breakpoints.down(600)]: {
        display: "none",
      },
    },
    searchContainer: {
      marginTop: "10px",
      position: "relative",
      marginBottom: "10px",
      width: "40%",
      height: "45px",
      backgroundColor: "#F6F6F6",
      [theme.breakpoints.down(600)]: {
        width: "100%",
      },
      "& input": {
        backgroundColor: "#F6F6F6",
        padding: "5px 5px",
        borderRadius: "5px",
        height: "100%",
        fontSize: "15px",
        [theme.breakpoints.between(1400, 1559)]: {
          fontSize: "17px !important",
        },
        [theme.breakpoints.between(1560, 1700)]: {
          fontSize: "18px !important",
        },
        [theme.breakpoints.between(1650, 1850)]: {
          fontSize: "20px !important",
        },
        [theme.breakpoints.between(1851, 2000)]: {
          fontSize: "22px !important",
        },
        width: "100%",
        boxSizing: "border-box",
        fontWeight: theme.typography.fontWeightBold,
        border: "1px solid transparent",
        "&:focus": {
          outline: "none",
        },
      },
    },
    notification: {
      marginTop: "10px",
      marginBottom: "10px",
      marginLeft: "10px",
      marginRight: "10px",
      padding: "12px",
      borderRadius: "50%",
      width: "2%",
      backgroundColor: "#F6F6F6",
      height: "20px",
      boxSizing: "unset",
      "& img": {
        boxSizing: "unset",
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down(600)]: {
        display: "none",
      },
    },
    profile: {
      backgroundColor: theme.palette.primary.light,
      width: "20%",
      display: "flex",
      alignItems: "center",
      padding: "0px 0px 0px 10px",
      boxSizing: "border-box",
      [theme.breakpoints.between(700, 900)]: {
        width: "10%",
      },
    },
    userProfileImage: {
      borderRadius: "50%",
      width: "20%",
      "& svg": {
        fontSize: "40px",
        color: "#fff",
        [theme.breakpoints.between(1560, 1700)]: {
          fontSize: "42px !important",
        },
        [theme.breakpoints.between(1650, 1850)]: {
          fontSize: "43px !important",
        },
        [theme.breakpoints.between(1851, 2000)]: {
          fontSize: "44px !important",
        },
      },
    },
    userName: {
      width: "65%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      paddingLeft: "5px !important",
      margin: "0px !important",
      whiteSpace: "nowrap",
      color: "#ffffff !important",
      [theme.breakpoints.down(900)]: {
        display: "none",
      },
      [theme.breakpoints.between(1560, 1700)]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.between(1650, 1850)]: {
        fontSize: "22px !important",
      },
      [theme.breakpoints.between(1851, 2000)]: {
        fontSize: "24px !important",
      },
      fontWeight: `${theme.typography.fontWeightMedium} !important`,
    },
    profileDropDown: {
      width: "10%",
      marginLeft: "-5px",
      marginBottom: "-5px",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down(900)]: {
        marginLeft: "26px",
      },
    },
    searchResultContainer: {
      position: "absolute",
      top: "46px",
      left: "0px",
      width: "100%",
      padding: "10px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      zIndex: "9999",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      backgroundColor: "#ffffff",
    },
    searchItem: {
      textAlign: "left",
      margin: "5px 0 !important",
      fontWeight: `${theme.typography.fontWeightLight} !important`,
      color: "#4A5568",
      "&:hover": {
        cursor: "pointer",
        color: `${theme.palette.primary.light} !important`,
      },
    },
    headerMobileContainer: {},
    searchResultTitle: {
      textAlign: "left",
      color: "#4A5568",
    },
    logoContainer: {
      padding: "9px 9px 9px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
      minWidth: "50px",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      "& img": {
        maxWidth: "37px",
      },
    },
  })
);
function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toggle } = useSelector(
    (state: ReducersModel) => state.toggleSideNavReducer
  );
  const [path, setPath] = React.useState("");
  const { data } = useSelector((state: ReducersModel) => state.userDataReducer);
  const { recentSearchResult, search, recentSearchResultDataQueryParameters } =
    useSelector((state: ReducersModel) => state.searchResultReducer);
  const [toggleProfileDropDown, setToggleProfileDropDown] =
    React.useState(false);
  // const [showDropdown, setShowDropdown] = React.useState(false);
  const { profileDropdown } = useSelector(
    (state: ReducersModel) => state.dropDownReducer
  );
  React.useEffect(() => {
    dispatch({
      type: Utils.ActionName.DROPDOWN,
      payload: { profileDropdown: false },
    });
    setToggleProfileDropDown(false);
  }, [window.location.pathname]);
  React.useEffect(() => {
    setPath(window.location.pathname.split("/")[2]);
  }, [window.location.pathname.split("/")]);
  React.useEffect(() => {
    dispatch(checkSubscription());
    const userData: any = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    dispatch({
      payload: { data: parsedUserData },
      type: Utils.ActionName.USER_DATA,
    });
    if (window.innerWidth <= 600) {
      dispatch({
        type: Utils.ActionName.TOGGLE_SIDENAV,
        payload: { toggle: true },
      });
    }
  }, []);
  const [enableSearch, setEnableSearch] = React.useState(false);
  React.useEffect(() => {
    if (enableSearch) {
      if (search !== "") {
        const parameterList = {
          ...recentSearchResultDataQueryParameters,
          type: "template",
          searchKey: search,
        };
        dispatch(templateList(parameterList, true));
      } else {
        const parameterList = {
          ...recentSearchResultDataQueryParameters,
        };
        dispatch(templateList(parameterList, false));
      }
      setEnableSearch(false);
    }
  }, [dispatch, enableSearch]);
  // window.addEventListener("beforeunload", function (e) {
  //   e.preventDefault();
  //   e.returnValue = "";

  //   const timeOnApp = Date.now() - Number(localStorage.getItem("startDate"));
  //   localStorage.setItem("timeOnApp", Date.now().toString());
  // });
  // React.useEffect(() => {
    
  // },[])
  return (
    <div
      // className={toggle? classes.headerContainer : classes.headerMobileContainer}
      className={classes.headerContainer}
      style={
        toggle
          ? {
              marginLeft: "65px",
              transition: "all 0.5s ease-in-out",
              width: "calc(100% - 65px)",
            }
          : {
              marginLeft: "249px",
              transition: "all 0.5s ease-in-out",
              width: "calc(100% - 249px)",
            }
      }
    >
      <div className={classes.logoContainer}>
        <img src={LocalImages.BRANDLOGO} alt="brand_logo" />
      </div>
      <div className={classes.toggleSideNav}>
        <Hamburger
          toggled={toggle}
          size={26}
          toggle={() => {
            dispatch({
              payload: { toggle: !toggle },
              type: Utils.ActionName.TOGGLE_SIDENAV,
            });
          }}
        />
      </div>
      {window.location.pathname.split("/").length <= 3 &&
      (window.location.pathname.split("/")[1] === "dashboard" ||
        window.location.pathname.split("/")[2] === "search") ? (
        <div className={classes.searchContainer}>
          <input
            type="text"
            value={search}
            placeholder="Search templates"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                setEnableSearch(true);
                if (window.location.pathname.split("/")[2] !== "search") {
                  navigate(Utils.Pathname.Search);
                }
              }
            }}
            onClick={() => {
              if (Object.keys(recentSearchResult).length === 0) {
                dispatch(getRecentSearchResults());
              }
            }}
            onChange={(e) => {
              dispatch({
                type: Utils.ActionName.SEARCH_RESULT,
                payload: { search: e.target.value },
              });
            }}
          />
          {recentSearchResult.length ? (
            <div className={classes.searchResultContainer}>
              <Typography className={classes.searchResultTitle}>
                Recent Searches
              </Typography>
              {recentSearchResult.map((value: any, index: number) => {
                return (
                  <Typography
                    className={classes.searchItem}
                    key={index}
                    onClick={() => {
                      dispatch({
                        type: Utils.ActionName.SEARCH_RESULT,
                        payload: { search: value, recentSearchResult: [] },
                      });
                      setEnableSearch(true);
                      navigate(Utils.Pathname.Search);
                    }}
                  >
                    {value}
                  </Typography>
                );
              })}
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
      ) : (
        <div style={{ width: "40%" }}></div>
      )}

      <div className={classes.notification}>
        <img src={LocalImages.NOTIFICATION} alt="notification_icon" />
      </div>
      <div className={classes.profile}>
        <div className={classes.userProfileImage}>
          <AccountCircleIcon />
        </div>
        <Typography variant="body2" className={classes.userName}>
          {data && data.name}
        </Typography>
        <div className={classes.profileDropDown}>
          <KeyboardArrowDownIcon
            onClick={(event) => {
              if (toggleProfileDropDown) {
                dispatch({
                  type: Utils.ActionName.DROPDOWN,
                  payload: { profileDropdown: false, filterDropdown: false },
                });
                setToggleProfileDropDown(!toggleProfileDropDown);
              } else {
                dispatch({
                  type: Utils.ActionName.DROPDOWN,
                  payload: { profileDropdown: true, filterDropdown: false },
                });
                setToggleProfileDropDown(!toggleProfileDropDown);
              }
            }}
            style={
              toggleProfileDropDown
                ? {
                    color: "#ffffff",
                    transform: "rotate(180deg)",
                    transition: "transform 0.2s ease-in-out",
                  }
                : { color: "#ffffff", transition: "transform 0.2s ease-in-out" }
            }
          />
          <Dropdown
            children={<DropdownContent />}
            right={"0px"}
            top={"50px"}
            showDropdown={profileDropdown}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
