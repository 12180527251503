import * as React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import { logoutApp } from "./action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Utils from "../../Utils";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileDropdownMenuContainer: {
      "& p": {
        display: "flex",
        alignItems: "center",
        fontSize: "15px !important",
        marginBottom: "10px !important",
        transition: "color 0.2s ease-in-out",
        "&:hover": {
          cursor: "pointer",
          color: `${theme.palette.primary.light} !important`,
        },
      },
    },
  })
);
export default function DropdownContent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (type: number) => {
    switch (type) {
      case 1:
        navigate(Utils.Pathname.PersonalDetails);
        dispatch({
          type: Utils.ActionName.DROPDOWN,
          payload: { profileDropdown: false },
        });
        break;
      case 2:
        navigate(Utils.Pathname.PaymentHistory);
        dispatch({
          type: Utils.ActionName.DROPDOWN,
          payload: { profileDropdown: false },
        });
        break;
      case 3:
        const isSubscribed = sessionStorage.getItem("isSubscribed");
        if (isSubscribed === "true") {
          navigate(Utils.Pathname.MySubscription);
        } else {
          navigate(Utils.Pathname.Subscription);
        }
        dispatch({
          type: Utils.ActionName.DROPDOWN,
          payload: { profileDropdown: false },
        });
        break;
      case 4:
        navigate(Utils.Pathname.Settings);
        dispatch({
          type: Utils.ActionName.DROPDOWN,
          payload: { profileDropdown: false },
        });
        break;
      case 5:
        dispatch(logoutApp(navigate, false));
        break;
      case 6:
        navigate(Utils.Pathname.LinkedAccount);
        break;
    }
  };

  return (
    <div className={classes.profileDropdownMenuContainer}>
      <Typography onClick={() => handleClick(1)} variant="body2">
        Personal Details
      </Typography>
      <Typography onClick={() => handleClick(2)} variant="body2">
        Payment History
      </Typography>
      <Typography onClick={() => handleClick(6)} variant="body2">
        Linked Social Media Account
      </Typography>
      <Typography onClick={() => handleClick(3)} variant="body2">
        Subscription
      </Typography>
      <Typography onClick={() => handleClick(4)} variant="body2">
        Settings
      </Typography>
      <Typography onClick={() => handleClick(5)} variant="body2">
        Log Out
      </Typography>
    </div>
  );
}
