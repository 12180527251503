import { combineReducers } from "redux";
import { userDataReducer } from "../screen/login/reducer";
import { signUpDataReducer } from "../screen/signup/reducer";
import { toggleSideNavReducer } from "../components/sideNavigation/reducer";
import {
  dashboardDataReducer,
  categoryListDataReducer,
  templateDetailsReducer,
} from "../screen/dashboard/reducer";
import {
  templateListDataReducer,
  searchResultReducer,
  dropDownReducer,
  subscriptionValidDataReducer,
} from "../components/header/reducer";
import { socialSignInReducer } from "../components/socialSignin/reducer";
import {
  teamMembersDataReducer,
  companyDataReducer,
} from "../screen/teams/reducer";
import {
  favListReducer,
  favTemplateListReducer,
  toggleModalReducer,
} from "../screen/myFavorites/reducer";
import { editableComponentReducer } from "../screen/videoEditor/reducer";
import { inputMatrixReducer } from "../screen/videoEditor/reducer";
import { videoUrlReducer } from "../screen/videoEditor/reducer";
import { faqReducer, supportReducer } from "../screen/settings/reducer";
import {
  editprofileReducer,
  profileReducer,
} from "../screen/personalDetails/reducer";
import {
  myTemplateReducer,
  editScheduledPostReducer,
  getDownladableUrlReducer,
} from "../screen/myTemplates/reducer";
import { subscriptionPlanReducer } from "../screen/subscription/reducer";
import {
  facebookPagesReducer,
  linkedInReducer,
  scheduleFacebookPostReducer,
  linkedinProfileReducer,
  ScheduleLinkedinPostReducer,
  instaAccessReducer,
  facebookLoginReducer,
} from "../components/scheduleLogin/reducer";
import { paymentHistoryDataReducer } from "../screen/paymentHistory/reducer";
import { linkedAccountReducer } from "../screen/linkedSocialMediaAccount/reducer";
const RootReducer = combineReducers({
  userDataReducer,
  signUpDataReducer,
  toggleSideNavReducer,
  dashboardDataReducer,
  templateListDataReducer,
  teamMembersDataReducer,
  companyDataReducer,
  socialSignInReducer,
  categoryListDataReducer,
  templateDetailsReducer,
  favListReducer,
  favTemplateListReducer,
  editableComponentReducer,
  inputMatrixReducer,
  videoUrlReducer,
  toggleModalReducer,
  searchResultReducer,
  dropDownReducer,
  faqReducer,
  supportReducer,
  editprofileReducer,
  profileReducer,
  myTemplateReducer,
  subscriptionPlanReducer,
  facebookPagesReducer,
  scheduleFacebookPostReducer,
  linkedInReducer,
  linkedinProfileReducer,
  ScheduleLinkedinPostReducer,
  instaAccessReducer,
  paymentHistoryDataReducer,
  subscriptionValidDataReducer,
  facebookLoginReducer,
  editScheduledPostReducer,
  linkedAccountReducer,
  getDownladableUrlReducer,
});
export default RootReducer;
