const Pathname = {
  SignUp: "/signup",
  OtherDetails: "/otherdetails",
  Verification: "/verification",
  SetPassword: "/setpassword",
  Login: "/login",
  ForgotPassword: "/forgotpassword",
  SetNewPassword: "/setnewpassword",
  Dashboard: "/dashboard",
  Settings: "/settings",
  MyFavorites: "/myfavorites",
  Teams: "/teams",
  MyTemplates: "/mytemplates",
  TemplateListing: "/dashboard/:template",
  ChangePassword: "/changepassword",
  CategoryListing: "/dashboard/categorylisting/:categoryName",
  Search: "/dashboard/search",
  TemplateDetails: "/dashboard/templatedetails/:templateName",
  FavTemplate: "/myfavorites/:name/:listId",
  TemplateListingDetail: "/dashboard/:template/:templatedetail",
  VideoEditor: "/video-editor/:templateId/:userId/:res",
  AboutUS: "/settings/aboutus",
  FAQ: "/settings/faq",
  TermsandConditions: "/settings/termsandconditions",
  ChangePasswordSetting: "/settings/changepassword",
  PrivacyPolicy: "/settings/privacypolicy",
  Subscription: "/subscription",
  PersonalDetails: "/personaldetails",
  MySubscription: "/mysubscription",
  PaymentStatus: "/paymentstatus/:status",
  PaymentHistory: "/paymenthistory",
  PaymentDetails: "/paymenthistory/:id",
  LinkedAccount: "/linkedAccount",
};
export default Pathname;
