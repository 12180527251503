import React, { lazy } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import PublicRoute from "../hoc/publicRoute/index";
import PrivateRoute from "../hoc/privateRoute/index";
import Utils from "../Utils";
const {
  SignUp,
  OtherDetails,
  Verification,
  SetPassword,
  Login,
  ForgotPassword,
  SetNewPassword,
  Dashboard,
  Teams,
  MyFavorites,
  MyTemplates,
  Settings,
  TemplateListing,
  ChangePassword,
  CategoryListing,
  Search,
  TemplateDetails,
  FavTemplate,
  TemplateListingDetail,
  VideoEditor,
  TermsandConditions,
  FAQ,
  AboutUS,
  ChangePasswordSetting,
  PrivacyPolicy,
  Subscription,
  PersonalDetails,
  MySubscription,
  PaymentStatus,
  PaymentHistory,
  PaymentDetails,
  LinkedAccount,
} = Utils.Pathname;
const LandingPage = lazy(() => import("../screen/index"));
const SignUpScreen = lazy(() => import("../screen/signup"));
const OtherScreen = lazy(() => import("../screen/signup/otherDetail"));
const VerificationScreen = lazy(() => import("../screen/signup/verification"));
const SetPasswordScreen = lazy(() => import("../screen/signup/setPassword"));
const LoginScreen = lazy(() => import("../screen/login"));
const ForgotPasswordScreen = lazy(
  () => import("../screen/login/forgotPassword")
);
const LinkedAccountScreen = lazy(
  () => import("../screen/linkedSocialMediaAccount")
);
const SetNewPasswordScreen = lazy(
  () => import("../screen/login/setNewPassword")
);
const ChangePasswordScreen = lazy(
  () => import("../screen/signup/changePassword")
);
const DashboardScreen = lazy(() => import("../screen/dashboard"));
const TemplateListingScreen = lazy(
  () => import("../screen/dashboard/templateListing")
);
const MyFavoritesScreen = lazy(() => import("../screen/myFavorites"));
const MyFavoriteTemplatesScreen = lazy(
  () => import("../screen/myFavorites/favTemplate")
);
const MyTemplatesScreen = lazy(() => import("../screen/myTemplates"));
const SettingsScreen = lazy(() => import("../screen/settings"));
const TeamsScreen = lazy(() => import("../screen/teams"));
const CategoryListingScreen = lazy(
  () => import("../screen/dashboard/categoryListing")
);
const TemplateDetailsScreen = lazy(() => import("../screen/dashboard/details"));
const ChangePasswordSettingScreen = lazy(
  () => import("../screen/settings/changePassword")
);
const FAQScreen = lazy(() => import("../screen/settings/faq"));
const AboutUsScreen = lazy(() => import("../screen/settings/aboutUs"));
const PrivacyPolicyScreen = lazy(
  () => import("../screen/settings/privacyPolicy")
);
const TermsAndConditionsScreen = lazy(
  () => import("../screen/settings/termsandconditions")
);
const PersonalDetailsScreen = lazy(() => import("../screen/personalDetails"));
const SearchScreen = lazy(() => import("../screen/dashboard/search"));
const VideoEditorScreen = lazy(() => import("../screen/videoEditor"));
const SubscriptionScreen = lazy(() => import("../screen/subscription"));
const MySubscriptionScreen = lazy(
  () => import("../screen/subscription/mySubscription")
);
const PaymentStatusScreen = lazy(
  () => import("../screen/subscription/paymentStatus")
);
const PaymentHistoryScreen = lazy(() => import("../screen/paymentHistory"));
const PaymentHistoryDetailsScreen = lazy(
  () => import("../screen/paymentHistory/paymentDetail")
);
import { LinkedInCallback } from "react-linkedin-login-oauth2";

const Routers: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0D0D0D;",
        light: "#FF3053",
      },
      secondary: {
        main: "#2C5282",
        light: "#4A5568",
      },
      text: {
        primary: "#616161",
        secondary: "#4A5568",
      },
      success: {
        main: "#ffffff",
      },
      info: {
        main: "#ffffff",
      },
    },
    typography: {
      fontFamily: "Poppins",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* LogIn And SignUp Routes*/}
          <Route
            path={Login}
            element={<PublicRoute component={<LoginScreen />} />}
          />
          <Route
            path={ForgotPassword}
            element={<PublicRoute component={<ForgotPasswordScreen />} />}
          />
          <Route
            path={SetNewPassword}
            element={<PublicRoute component={<SetNewPasswordScreen />} />}
          />
          <Route
            path={SignUp}
            element={<PublicRoute component={<SignUpScreen />} />}
          />
          <Route
            path={OtherDetails}
            element={<PublicRoute component={<OtherScreen />} />}
          />
          <Route
            path={Verification}
            element={<PublicRoute component={<VerificationScreen />} />}
          />
          <Route
            path={SetPassword}
            element={<PublicRoute component={<SetPasswordScreen />} />}
          />
          <Route
            path={ChangePassword}
            element={<PublicRoute component={<ChangePasswordScreen />} />}
          />
          {/* LogIn And SignUp Routes*/}
          {/* --------------------------------------------------------- */}
          {/* Private Routes */}
          <Route
            path={Dashboard}
            element={<PrivateRoute component={<DashboardScreen />} />}
          />
          <Route
            path="/linkedin"
            element={<PrivateRoute component={<LinkedInCallback />} />}
          />
          <Route
            path={TemplateDetails}
            element={<PrivateRoute component={<TemplateDetailsScreen />} />}
          />
          <Route
            path={TemplateListing}
            element={<PrivateRoute component={<TemplateListingScreen />} />}
          />
          <Route
            path={TemplateListingDetail}
            element={<PrivateRoute component={<TemplateDetailsScreen />} />}
          />

          <Route
            path={Search}
            element={<PrivateRoute component={<SearchScreen />} />}
          />
          <Route
            path={LinkedAccount}
            element={<PrivateRoute component={<LinkedAccountScreen />} />}
          />

          <Route
            path={CategoryListing}
            element={<PrivateRoute component={<CategoryListingScreen />} />}
          />

          <Route
            path={MyTemplates}
            element={<PrivateRoute component={<MyTemplatesScreen />} />}
          />
          <Route
            path={MyFavorites}
            element={<PrivateRoute component={<MyFavoritesScreen />} />}
          />
          <Route
            path={FavTemplate}
            element={<PrivateRoute component={<MyFavoriteTemplatesScreen />} />}
          />
          <Route
            path={Settings}
            element={<PrivateRoute component={<SettingsScreen />} />}
          />
          <Route
            path={PersonalDetails}
            element={<PrivateRoute component={<PersonalDetailsScreen />} />}
          />
          <Route
            path={Teams}
            element={<PrivateRoute component={<TeamsScreen />} />}
          />
          <Route
            path={VideoEditor}
            element={<PrivateRoute component={<VideoEditorScreen />} />}
          />

          <Route
            path={AboutUS}
            element={<PrivateRoute component={<AboutUsScreen />} />}
          />
          <Route
            path={PrivacyPolicy}
            element={<PrivateRoute component={<PrivacyPolicyScreen />} />}
          />
          <Route
            path={TermsandConditions}
            element={<PrivateRoute component={<TermsAndConditionsScreen />} />}
          />
          <Route
            path={FAQ}
            element={<PrivateRoute component={<FAQScreen />} />}
          />
          <Route
            path={ChangePasswordSetting}
            element={
              <PrivateRoute component={<ChangePasswordSettingScreen />} />
            }
          />
          <Route
            path={Subscription}
            element={<PrivateRoute component={<SubscriptionScreen />} />}
          />
          <Route
            path={MySubscription}
            element={<PrivateRoute component={<MySubscriptionScreen />} />}
          />
          <Route
            path={PaymentStatus}
            element={<PrivateRoute component={<PaymentStatusScreen />} />}
          />
          <Route
            path={PaymentHistory}
            element={<PrivateRoute component={<PaymentHistoryScreen />} />}
          />
          <Route
            path={PaymentDetails}
            element={
              <PrivateRoute component={<PaymentHistoryDetailsScreen />} />
            }
          />
          <Route
            path={"/"}
            element={<PublicRoute component={<LandingPage />} />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};
export default Routers;
