const LocalImages = {
  FB_LOGO:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/fb.png",
  Facebook:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/fb.svg",
  Google:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/google.svg",
  LeftDivImg:
    "https://infiniteview-preprod-backend-bucket.s3.us-west-1.amazonaws.com/amma/1668679705972-loginimage.png",
  AMMA_LOGO:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/amma_logo.svg",
  DASHBOARD_ACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/dashboard_white.svg",
  DASHBOARD_INACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/dashboard_black.svg",
  GROUP:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/Group.png",
  NOTIFICATION:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/notification.svg",
  FAVORITE_LOGO_ACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/fav_light.svg",
  FAVORITE_LOGO_INACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/fav_dark.svg",
  SETTINGS_LOGO_ACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/settings_light.svg",
  SETTINGS_LOGO_INACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/settings_dark.svg",
  TEAMS_LOGO_ACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/teams_light.svg",
  TEAMS_LOGO_INACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/teams_dark.svg",
  TEMPLATES_LOGO_ACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/template_light.svg",
  TEMPLATES_LOGO_INACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/template_dark.svg",
  BRANDLOGO:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/brandlogo.svg",
  BRANDNAME:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/brandname.svg",
  TEAM_ADD:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/addmember.svg",
  TEAM_EDIT:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/editmember.svg",
  TEAMS_EDIT:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/editteam.svg",
  TEAM_REMOVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/removemember.svg",
  HELLO:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/hello.svg",
  DOTS: "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/dots.svg",
  DEMO: "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/demo.png",
  FAV_LIST_EDIT:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/favListEdit.svg",
  FAV_LIST_DEL:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/favListDelete.svg",
  DELETE_LIST_MODAL:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/deleteListModalIcon.svg",
  COLORWHEEL:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/color-wheel.svg",
  KEYBOARDACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-keyboard-active.svg",
  KEYBOARDINACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-keyboard-inactive.svg",

  BOTTOMCOLORICONACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-color-palet-active.svg",
  BOTTOMCOLORICONINACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-color-palet-inactive.svg",

  BOTTOMIMAGEICONACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-image-selector-active.svg",
  BOTTOMIMAGEICONINACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-image-selector-inactive.svg",

  BOTTOMDELETEICONINACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/bottomDeleteIcon.svg",

  BOTTOMSAVEACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-save-active.svg",
  BOTTOMSAVEINACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-save-inactive.svg",

  BOTTOMDOWNLOADACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-download-active.svg",
  BOTTOMDOWNLOADINACTIVE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/ic-download-inactive.svg",

  PHOTO_CAMERA_BLACK:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/photo_camera_black.svg",
  FilterIcon:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/FilterIcon.svg",
  IMAGETHUMBNAIL:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/thumbnail.jpg",
  TEMPLATEDOWNLOAD:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/download.svg",
  TEMPLATEFACEBOOK:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/fb.svg",
  TEMPLATEINSTAGRAM:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/instagram.svg",
  TEMPLATELINKEDIN:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/linkedin.svg",
  SCHEDULE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/schedule.svg",
  TEMPLATESHARE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/share.svg",
  PROFILEIMAGE:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/profileImg.png",
  CHECK:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/check.svg",
  STATUS_CHECK:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/checked.svg",
  STATUS_CROSS:
    "https://infiniteview-preprod-backend-bucket.s3.amazonaws.com/amma/images/cross.svg",

  // CONGRATS_ARROW: `${baseUrl}/images/gif_images/congrates-to-buyers-and-sellers_04/arrow.gif`,
  //   CONGRATS_ICON: `${baseUrl}/images/gif_images/congrates-to-buyers-and-sellers_04/icon.gif`,
  //   CONGRATS_CONFETTI: `${baseUrl}/images/gif_images/congrates-to-buyers-and-sellers_04/confetti_1080_1920.gif`

  FIVE_STAR_REVIEW_03_STARS:
    "/images/gif_images/five-star-review_03/small/stars",
  FIVE_STAR_REVIEW_03_HOUSE:
    "/images/gif_images/five-star-review_03/small/new_house",
  CONGRATS_TO_BUYERS_SELLERS_01_CONFITTI:
    "/images/gif_images/congrats-to-buyers-and-sellers_01/compressed/confetti",
  CONGRATS_TO_BUYERS_SELLERS_02_FIREWORK:
    "/images/gif_images/congrats-to-buyers-and-sellers_02/small/fireworks",
  CONGRATS_TO_BUYERS_SELLERS_03_CONFITTI:
    "/images/gif_images/congrats-to-buyers-and-sellers_03/more-compressed/confetti",
  CONGRATS_TO_BUYERS_SELLERS_03_WAVES:
    "/images/gif_images/congrats-to-buyers-and-sellers_03/more-compressed/waves",
  CONGRATS_TO_BUYERS_SELLERS_04_ICON:
    "/images/gif_images/congrats-to-buyers-and-sellers_04/small/icon",
  CONGRATS_TO_BUYERS_SELLERS_04_ARROW:
    "/images/gif_images/congrats-to-buyers-and-sellers_04/small/arrow",
  CONGRATS_TO_BUYERS_SELLERS_04_CONFETTI:
    "/images/gif_images/congrats-to-buyers-and-sellers_04/small/confetti",
  CONGRATS_TO_BUYERS_SELLERS_05_SPARKELS:
    "/images/gif_images/congrats-to-buyers-and-sellers_05/compressed/sparkles",
  CONGRATS_TO_BUYERS_SELLERS_05_CONFETTI:
    "/images/gif_images/congrats-to-buyers-and-sellers_05/compressed/confetti",
  HAPPY_INDIPENDENCE_DAY_TOP:
    "/images/gif_images/happy_Independence_Day/compressed/flag_top",
  HAPPY_INDIPENDENCE_DAY_BOTTOM:
    "/images/gif_images/happy_Independence_Day/compressed/flag_bottom",
  HAPPY_MEMORIAL_DAY_FLAG:
    "/images/gif_images/happy_memorial_day/small/memorial_flag",
  HAPPY_MEMORIAL_DAY_STAR_1:
    "/images/gif_images/happy_memorial_day/small/star_1",
  HAPPY_MEMORIAL_DAY_STAR_3:
    "/images/gif_images/happy_memorial_day/small/star_3",
  VETERANS_DAY_FALG: "/images/gif_images/veterans_day/compressed/flag",
  VETERANS_DAY_STARS: "/images/gif_images/veterans_day/compressed/stars",
  MONDAY_MOTIVATION_1_SPARKELS:
    "/images/gif_images/monday-motivation-01/sparkels",
  MONDAY_MOTIVATION_2_SEMICOLEN:
    "/images/gif_images/monday-motivation-02/semicolon",

  REMODE_03_ARROW: "/images/gif_images/remodel-03/arrow",
  REMODEL_02_BEFORE: "/images/gif_images/remodel-02/compressed/before",
  REMODEL_02_AFTER: "/images/gif_images/remodel-02/compressed/after",
  HAPPY_BIRTHDAY_DESIGN: "/images/gif_images/happy_birthday_02/small/design",
  FIVE_STAR_REVIEW_02_STAR: "/images/gif_images/five-star-review-02/",

  // HALLOWEEN_MOON_OBJECT: "images/gif_images/halloween_day/images/moon_4",
  HALLOWEEN_MOON_OBJECT: "images/gif_images/halloween_day/small/moon_4",
  // HALLOWEEN_BAT: "images/gif_images/halloween_day/images/bat_compressed",
  HALLOWEEN_BAT: "images/gif_images/halloween_day/small/bat",
  HALLOWEEN_TEXT: "images/gif_images/halloween_day/images/text",

  FATHERS_DAY_TIE: "images/gif_images/fathers_day/small/tie",
  FATHERS_DAY: "images/gif_images/fathers_day/fathers-day",
  FATHERS_DAY_FROM: "images/gif_images/fathers_day/from",
  FATHERS_DAY_HAPPY: "images/gif_images/fathers_day/text",

  // NEW_YEAR_BALLON: "images/gif_images/happy_new_year/ballon",
  // NEW_YEAR_CONFETTI: "images/gif_images/happy_new_year/new_year_confetti",
  NEW_YEAR_BALLON: "images/gif_images/happy_new_year/mini/ballon",
  NEW_YEAR_CONFETTI: "images/gif_images/happy_new_year/mini/new_year_confetti",
  NEW_YEAR_TEXT: "images/gif_images/happy_new_year/new_year_text",

  REMODEL_01_BEFORE: "images/gif_images/remodel-01/new/before.gif",
  REMODEL_01_AFTER: "images/gif_images/remodel-01/new/after.gif",
  REMODEL_01_BEFORE_AFTER: "images/gif_images/remodel-01/new/before_after_bg.gif",

  CHRISTMAS_TREE: "images/gif_images/merry_christmas/images/small/snow_bell",
  CHRISTMAS_TEXT: "images/gif_images/merry_christmas/images/text",

  HANUKKAH_DAY_LOGO: "images/gif_images/happyHanukkah/logo",
  HANUKKAH_DAY_TEXT: "images/gif_images/happyHanukkah/compressed/text",
  HANUKKAH_DAY_CANDLES: "images/gif_images/happyHanukkah/compressed/candles",
  TEST_VIDEO: "images/test.mp4"
};

export default LocalImages;
